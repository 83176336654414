import Localforage from "localforage";

export const setObject = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getObject = (key) => {
  try {
    const getLocalforageData = localStorage.getItem(key);
    return getLocalforageData ? JSON.parse(getLocalforageData) : null;
  } catch (e) {
    return null;
  }
};

export const setPrimitive = (key, value) => {
  localStorage.setItem(key, value.toString());
};

export const getPrimitive = (key) => {
  return localStorage.getItem(key);
};

export const removeLocalforage = (key) => {
  localStorage.removeItem(key);
};

export const clear = () => {
  localStorage.clear();
};

export const setAsyncObject = async (key, value) => {
  try {
    await Localforage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.warn(error);
  }
};

export const getAsyncObject = (key) => {
  try {
    const getLocalforageData = Localforage.getItem(key);
    const getLocalforageParsed = getLocalforageData
      ? JSON.parse(getLocalforageData)
      : null;
    return getLocalforageParsed;
  } catch (error) {
    console.warn(error);
  }
};

export const setAsyncPrimitive = (key, value) => {
  try {
    Localforage.setItem(key, value.toString());
  } catch (error) {
    console.warn(error);
  }
};

export const getAsyncPrimitive = async (key) => {
  try {
    const primitive = await Localforage.getItem(key);
    return primitive;
  } catch (error) {
    console.warn(error);
  }
};

export const removeAsyncLocalforage = (key) => {
  try {
    Localforage.removeItem(key);
  } catch (error) {
    console.warn(error);
  }
};

export const asyncClear = async () => {
  try {
    await Localforage.clear();
  } catch (error) {
    console.warn(error);
  }
};
