import * as types from "../../types/form";

const initialState = {
  form: {},
  loading: true,
  relationshipFilter: 0,
  model: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case types.ATUALIZA_FORM:
      return { ...state, erro: "", form: payload, loading: false };

    case types.ATUALIZA_MODEL:
      return { ...state, erro: "", model: payload };

    case types.ATUALIZA_CARREGANDO:
      return { ...state, erro: "", loading: payload };

    case types.CLEAR_STATE:
      return { ...initialState, erro: "" };

    // case types.ATUALIZA_ID_DE_FILTRO_POR_RELACIONAMENTO:
    //   return { ...state, erro: "", relationshipFilter: payload };

    default:
      return state;
  }
};
