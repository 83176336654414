import axios from "./api";
import { getToken, isAuthenticated, login, logout, refreshToken } from "./auth";
import apiBase from "./base";
import {
  clear,
  getObject,
  getPrimitive,
  removeLocalforage,
  setObject,
  setPrimitive,
} from "./store";

export {
  axios,
  apiBase,
  isAuthenticated,
  getToken,
  login,
  logout,
  refreshToken,
  clear,
  getObject,
  getPrimitive,
  removeLocalforage,
  setObject,
  setPrimitive,
};
