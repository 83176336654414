//import chatData from './chatData';

const personChat = (state = [], action) => {
  switch (action.type) {
    case "PERSON_CHAT":
      return action.person;
    default:
      return state;
  }
};

export default personChat;
