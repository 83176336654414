//import chatData from './chatData';

const chats = (state = [], action) => {
  switch (action.type) {
    case "FIND_CHAT":
      //return action.list.map((a) => a);
      return action.list;
    case "SEND_MSG":
      return state.map((chat) => {
        if (chat.id === action.id) {
          chat.chats.push({
            chat: action.chatMsg,
            personChat: { id: action.id, user: { id: chat.user.id } },
          });
          return chat;
        } else return chat;
      });
    default:
      return state;
  }
};

export default chats;
